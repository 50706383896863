<template>
   <div class="row my-1">
      <div class="col-10">
         <b>
            <label class="text-color justify-content-md-center">I wish to take a user reference check of the clinic / doctor whose details are given below.</label>
         </b>
      </div>
   </div>
   
   <div class="row mt-3">
      <div class="col-6">
         <div class="form-group">
               <label class="text-color">City</label>
               <input type="text" id="patient_name" name="patient_name" class="form-control"/>
         </div>
      </div>
   </div>

   <div class="row mt-3">
      <div class="col-12">
         <div class="form-group">
               <label class="text-color">Name of the doctor / clinic</label>
               <input type="text" id="patient_name" name="patient_name" class="form-control"/>
         </div>
      </div>
   </div>
   
   <div class="row mt-3">
      <hr class="hr-border" />
   </div>

   <div class="row mt-3">
      <div class="col-9">
         <label class="text-color justify-content-md-center">The amount payable by you is Rs. XXX/-</label>
      </div>
      <div class="col-3">
         <button type="button" class="btn btn-color text-white">Pay</button>
      </div>
   </div>
</template>
<script lang='js' src='./reference-check.js'></script>